import React from "react";
import { Link } from "react-router-dom";

function StarChatSettings() {
  return (
    <div className="p-4 space-y-8">
      <h2 className="text-2xl font-semibold text-gray-800">
        Star Chat Settings
      </h2>

      {/* Blocked Contacts */}
      <div className="flex justify-between p-3 border border-gray-200 rounded">
        <h3 className="text-lg font-medium text-gray-700">Blocked Contacts</h3>
        <Link
          to="/settings"
          className="my-auto hover:underline hover:text-blue-500"
        >
          Manage blocked users
        </Link>
      </div>

      {/* Lock Chats */}
      <div className="flex items-center justify-between p-3 border border-gray-200 rounded">
        <h3 className="text-lg font-medium text-gray-700">Lock Chats</h3>
        <div className="flex">
          <button className="text-white bg-blue-500 ml-auto p-2 rounded">
            Manage Password
          </button>
          <button className="text-white bg-blue-500 ml-4 p-2 rounded">
            See Locked Chats
          </button>
        </div>
      </div>
    </div>
  );
}

export default StarChatSettings;
