import { useState } from "react";
import emailjs from "emailjs-com";
import { auth, db } from "../firebase";
import { FiMail } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { signInWithEmailAndPassword } from "firebase/auth";
import LoginRegisterTile from "../appComponents/LoginRegisterTile";
import { collection, getDocs, query, where } from "firebase/firestore";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [twoFaState, setTwoFaState] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const sanitizeInput = (input) => input.trim();

  // Handle the submission of the login form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sanitize email and password
    const sanitizedEmail = sanitizeInput(email);
    const sanitizedPassword = sanitizeInput(password);

    try {
      // Fetch the 2FA state and use the result directly
      const twoFAState = await fetchTwoFAState(sanitizedEmail);

      if (twoFAState) {
        // If 2FA is enabled, validate the verification code
        if (!verificationCode) {
          setTwoFaState(true);
          return;
        }

        if (verificationCode !== generatedCode) {
          alert("Invalid verification code. Please try again.");
          return;
        }
      }

      // Proceed with login if 2FA is disabled or verification succeeds
      await signInWithEmailAndPassword(auth, sanitizedEmail, sanitizedPassword);
      navigate("/");
    } catch (err) {
      console.error("Error during login:", err.message);
      alert("There was an error");
    }
  };

  // Fetch the current two-factor auth state
  const fetchTwoFAState = async (sanitizedEmail) => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", sanitizedEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data();
        return docData.systemTwoFA || false;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching 2FA state:", error);
      return false;
    }
  };

  // Send the 2FA verification code email
  const sendVerificationEmail = async () => {
    const sanitizedEmail = sanitizeInput(email);
    const code = Math.floor(100000 + Math.random() * 900000).toString();
    setGeneratedCode(code);

    const emailParams = {
      to_email: sanitizedEmail,
      verification_code: code,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TWOFA_TEMPLATE_ID,
        emailParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      alert("Verification email sent successfully!");
    } catch (error) {
      alert("Failed to send email. Please try again later.");
    }
  };

  return (
    <>
      <LoginRegisterTile />
      <div className="min-h-[93vh] flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="relative bg-white p-10 rounded-xl flex flex-col items-center space-y-6 w-full max-w-md shadow-2xl"
        >
          {/* Gradient Shadow */}
          <div
            className="absolute inset-0 rounded-xl"
            style={{
              zIndex: -1,
              background: "linear-gradient(135deg, #ffec99, #9f7aea, #3b82f6)",
              filter: "blur(20px)",
            }}
          ></div>

          <h3 className="text-3xl font-mrat font-semibold text-center tracking-tight">
            Login
          </h3>
          <div className="w-full">
            <label htmlFor="email" className="block text-gray-500 mb-1">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(sanitizeInput(e.target.value))}
              placeholder="Enter email address"
              className="w-full font-medium text-gray-700 text-lg p-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="w-full relative">
            <label htmlFor="password" className="block text-gray-500 mb-1">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(sanitizeInput(e.target.value))}
              placeholder="Enter password"
              className="w-full font-medium text-gray-700 text-lg p-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-11 flex items-center cursor-pointer text-gray-500"
            >
              {showPassword ? <LuEye /> : <LuEyeOff />}
            </div>
          </div>

          {/* Conditional rendering of the code verification input */}
          {twoFaState && (
            <div className="w-full relative">
              <label
                htmlFor="verificationCode"
                className="block text-gray-500 mb-1"
              >
                Verification Code
              </label>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Enter verification code"
                className="w-full font-medium text-gray-700 text-lg p-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 pr-10"
                required
              />
              <div className="absolute p-2 right-2 top-9 rounded-full bg-indigo-500 text-white cursor-pointer">
                <FiMail
                  className=""
                  title="Send verification code"
                  onClick={() => sendVerificationEmail()}
                />
              </div>
            </div>
          )}
          <button
            type="submit"
            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-lg font-semibold duration-150 ease-in-out"
          >
            {twoFaState ? "Verify and Login" : "Login"}
          </button>
        </form>
      </div>
    </>
  );
}

export default Login;
