import { FiX } from "react-icons/fi";
import { db, auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import ReportBugButton from "./ReportBugButton";
import { AuthContext } from "../context/AuthContext";
import { FaUser, FaCog, FaSignOutAlt } from "react-icons/fa";
import { useState, useEffect, useContext, useMemo } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";

function UserProfileTile({ isCollapsed, toggleCollapse }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const { currentUser } = useContext(AuthContext);

  // Fetch username
  useEffect(() => {
    const fetchUsername = async () => {
      try {
        if (currentUser?.email) {
          const nameQuery = query(
            collection(db, "users"),
            where("email", "==", currentUser.email)
          );
          const querySnapshot = await getDocs(nameQuery);
          querySnapshot.forEach((doc) => {
            setUsername(doc.data().username);
          });
        }
      } catch (error) {
        alert(error.message);
      }
    };

    fetchUsername();
  }, [currentUser]);

  // User Sign Out
  const userSignOut = () => {
    signOut(auth);
    window.location.reload(false);
  };

  // Memoized content for the expanded user profile
  const userProfileContent = useMemo(
    () => (
      <div className="flex p-4 items-center rounded-md">
        <img
          src="https://via.placeholder.com/40"
          alt="Profile"
          className="w-6 rounded-full"
        />
        <span className="ml-2 text-center text-md font-bold text-custom-gray-2 max-w-24 break-words">
          {username.length > 18 ? `${username.slice(0, 18)}...` : username}
        </span>

        <div className="flex space-x-5 ml-auto">
          <FaUser
            className="text-custom-gray-2 hover:text-gray-600 cursor-pointer"
            onClick={() => navigate("/profile")}
            title="Profile"
          />
          <FaCog
            className="text-custom-gray-2 hover:text-gray-600 cursor-pointer"
            onClick={() => navigate("/settings")}
            title="Settings"
          />
          <FaSignOutAlt
            className="text-custom-gray-2 hover:text-gray-600 cursor-pointer"
            onClick={() => userSignOut()}
            title="Sign Out"
          />
        </div>
      </div>
    ),
    [username, navigate]
  );

  // Memoized content for the collapsed user profile
  const collapsedContent = useMemo(
    () => (
      <div className="block">
        <div className="space-y-5 ml-6">
          <FaUser
            className="text-custom-gray-2 hover:text-gray-600 cursor-pointer"
            onClick={() => navigate("/profile")}
            title="Profile"
          />
          <FaCog
            className="text-custom-gray-2 hover:text-gray-600 cursor-pointer"
            onClick={() => navigate("/settings")}
            title="Settings"
          />
          <FaSignOutAlt
            className="text-custom-gray-2 hover:text-gray-600 cursor-pointer"
            onClick={() => userSignOut()}
            title="Sign Out"
          />
        </div>
        <ReportBugButton
          toggleCollapse={toggleCollapse}
          isCollapsed={isCollapsed}
        />
        <div className="w-16 border-t border-gray-700"></div>
        <div className="p-3 ml-1 items-center rounded-md">
          <img
            src="https://via.placeholder.com/40"
            alt="Profile"
            className="w-8 rounded-full"
          />
        </div>
      </div>
    ),
    [navigate, toggleCollapse, isCollapsed]
  );

  return (
    <div>
      {!isCollapsed ? (
        <>
          <ReportBugButton
            toggleCollapse={toggleCollapse}
            isCollapsed={isCollapsed}
          />
          <div className="w-64 border-t border-gray-700"></div>
          {userProfileContent}
        </>
      ) : (
        collapsedContent
      )}
    </div>
  );
}

export default UserProfileTile;
