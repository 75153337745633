import SettingsSidebar from "../appComponents/settingsComponents/SettingsSidebar";
import { Outlet } from "react-router-dom";

function Settings({ isNavOpen }) {
  return (
    <div
      className={`flex h-[calc(100vh-1.8rem)] -mt-1 transition-all duration-300 ${
        isNavOpen ? "ml-72" : "ml-14"
      }`}
    >
      {/* Container for the settings page */}
      <div className="flex p-6 border border-gray-300 rounded-lg shadow-md w-full bg-white">
        {/* Sidebar */}
        <SettingsSidebar />

        {/* Divider */}
        <div className="w-px -my-6 bg-gray-300 mx-4" />

        {/* Main Content */}
        <div className="flex-grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Settings;
